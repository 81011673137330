// Option A: Include all of Bootstrap

// Include any default variable overrides here (though functions won't be available)

// $primary: #0074d9;
// $danger: #ff4136;

// $theme-colors: (
//   "primary":    $primary,
//   "secondary":  $secondary,
//   "success":    $success,
//   "info":       $info,
//   "warning":    $warning,
//   "danger":     $danger,
//   "light":      $light,
//   "dark":       $dark
// );

@import "../node_modules/bootstrap/scss/bootstrap";

// Then add additional custom code here

.map-position {
  background-color: rgba(43, 48, 53, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  font-size: 16px;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 8px;
  border-radius: 4px;
  line-height: 24px;
  height: 38px;
}

.toast-message {
  background: rgba(43, 48, 53, 0.9);
  color: #fff;
  font-size: 20px;
  width: 34vw;
  padding: 30px 20px;
}

// @charset "UTF-8";

// @import '../node_modules/react-toastify/scss/_variables';


// entrance and exit animations
// @import '../node_modules/react-toastify/scss/_animations/bounce.scss';
// @import '../node_modules/react-toastify/scss/_animations/zoom.scss';
// @import '../node_modules/react-toastify/scss/_animations/flip.scss';
// @import '../node_modules/react-toastify/scss/_animations/slide.scss';
// @import '../node_modules/react-toastify/scss/_animations/spin.scss';

// $rt-namespace: 'Toastify';
// $rt-mobile: 'only screen and (max-width : 480px)' !default;

// :root {
//   --toastify-color-light: #fff;
//   --toastify-color-dark: rgba(43, 48, 53, 1);
//   --toastify-color-info: #3498db;
//   --toastify-color-success: #07bc0c;
//   --toastify-color-warning: #f1c40f;
//   --toastify-color-error: #e74c3c;
//   --toastify-color-transparent: rgba(255, 255, 255, 0.7);

//   --toastify-icon-color-info: var(--toastify-color-info);
//   --toastify-icon-color-success: var(--toastify-color-success);
//   --toastify-icon-color-warning: var(--toastify-color-warning);
//   --toastify-icon-color-error: var(--toastify-color-error);

//   --toastify-toast-width: 320px;
//   --toastify-toast-background: #fff;
//   --toastify-toast-min-height: 64px;
//   --toastify-toast-max-height: 800px;
//   --toastify-font-family: sans-serif;
//   --toastify-z-index: 9999;

//   --toastify-text-color-light: #757575;
//   --toastify-text-color-dark: #fff;

//   //Used only for colored theme
//   --toastify-text-color-info: #fff;
//   --toastify-text-color-success: #fff;
//   --toastify-text-color-warning: #fff;
//   --toastify-text-color-error: #fff;

//   --toastify-spinner-color: #616161;
//   --toastify-spinner-color-empty-area: #e0e0e0;

//   // Used when no type is provided
//   // toast("**hello**")
//   --toastify-color-progress-light: linear-gradient(
//     to right,
//     #4cd964,
//     #5ac8fa,
//     #007aff,
//     #34aadc,
//     #5856d6,
//     #ff2d55
//   );
//   // Used when no type is provided
//   --toastify-color-progress-dark: #bb86fc;
//   --toastify-color-progress-info: var(--toastify-color-info);
//   --toastify-color-progress-success: var(--toastify-color-success);
//   --toastify-color-progress-warning: var(--toastify-color-warning);
//   --toastify-color-progress-error: var(--toastify-color-error);

//   // used to control the opacity of the progress trail
//   --toastify-color-progress-bgo: .2;
// }

// @import '../node_modules/react-toastify/scss/_toastContainer';
// @import '../node_modules/react-toastify/scss/_toast';
// @import '../node_modules/react-toastify/scss/_theme';
// @import '../node_modules/react-toastify/scss/_closeButton';
// @import '../node_modules/react-toastify/scss/_progressBar';
// @import '../node_modules/react-toastify/scss/_icons';







@keyframes myAnim {
	0% {
		opacity: 0;
		transform: translateY(250px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}




